import { Suspense, useContext } from 'react';
import { Stack, Typography, Menu, MenuItem, Grid } from '@mui/material';
import { FoldingDrawer } from 'components/drawer';
import { DataTemplatesContext, ThemeContext } from 'context';
import NavigationHeader from 'components/cards/navigation-header';
import { PageLayoutContext } from 'context/page-layout-context';
import { StyledSwitch } from 'components/styles/switch-styles';
import TemplatesList from 'components/data-templates-list';
import PageLayoutBox from 'components/layout/page-layout-styles';
import useTemplatesPage from 'routes/templates/hooks/use-templates-page';
import { StyledBox } from 'routes/parameters-page/parameters-page-styles';
import handleDataTemplatesFilters from './hooks/handle-data-templates-filters';

const DataTemplates = () => {
  const {
    dataTemplates,
    loading,
    loadingAssets,
    handleOpenCloseWizard,
    rowSelectionModel,
    setRowSelectionModel,
  } = useContext(DataTemplatesContext);
  const { localTheme } = useContext(ThemeContext);
  const { openTemplatePanels, numberOfTemplatePanelsOpen } =
    useContext(PageLayoutContext);
  const { handleDataTemplatesChange } = useTemplatesPage();

  const { handleClick, handleClose, openSwitchMenu, anchorEl, filterMenu } =
    handleDataTemplatesFilters();
  return (
    <FoldingDrawer
      open={openTemplatePanels.openTemplates}
      width={numberOfTemplatePanelsOpen > 2 ? '5%' : '20%'}
      tooltip="templates:templates"
      onClick={handleDataTemplatesChange}
    >
      <PageLayoutBox>
        <Suspense fallback={null}>
          <StyledBox aria-label="templates-page">
            <Suspense fallback={null}>
              <Grid container>
                <NavigationHeader
                  open
                  label="templates"
                  hasProjectCard
                  handleAdd={handleOpenCloseWizard}
                  disabled
                />
              </Grid>
            </Suspense>
            <TemplatesList
              style={{ width: '100%' }}
              rows={dataTemplates}
              loading={loading || loadingAssets}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
            />
            <Menu
              aria-label="toggle-menu"
              open={openSwitchMenu}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClick}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              {filterMenu?.map(({ switchLabel, handleChange, isChecked }) => {
                return (
                  <MenuItem
                    key={switchLabel}
                    checked={!isChecked}
                    onKeyDown={event => {
                      if (event.key === ' ' || event.key === 'Enter') {
                        event.preventDefault();
                        handleChange(event);
                      }
                    }}
                  >
                    <Stack direction="row">
                      <StyledSwitch
                        palette={localTheme}
                        aria-label="toggle-switch"
                        size="small"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <Typography variant="body2">{switchLabel}</Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Menu>
          </StyledBox>
        </Suspense>
      </PageLayoutBox>
    </FoldingDrawer>
  );
};

export default DataTemplates;
